import React from "react";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { graphql } from "gatsby";
import { HeroBanner } from "../components/hero-banner";
import { IFluidImage, IChildImageSharpFluid } from "../interfaces";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { Banner } from "../components/banner";
import { colors } from "../utilities";
import { Wrapper } from "../components/wrapper";
import { P, PSize, PControl } from "../components/typography/p";
import { DangerouslySetInnerHtml } from "../components/dangerously-set-inner-html";

export interface ITestimonialPageProps {
  data: ITestimonialPageQuery;
}

const Testimonial = styled.div({
  borderBottom: `4px solid ${colors.grey.base}`,
  textAlign: "center",
  padding: `0 16px 16px`,
  marginBottom: 40,
  "&:last-child": {
    borderBottom: "none",
  },
});

const TestimonialPage: React.SFC<ITestimonialPageProps> = ({ data }) => (
  <Layout>
    <SEO title="Testimonials" />
    <HeroBanner
      backgroundImageSrc={
        data.content.frontmatter.heroImg.childImageSharp.fluid.src
      }
      title={data.content.frontmatter.title}
    />
    <Banner />
    <Wrapper css={{ paddingTop: 64, paddingBottom: 64 }}>
      {data.content.frontmatter.testimonials.map((t, i) => (
        <Testimonial key={i}>
          <PControl size={PSize.large}>
            <DangerouslySetInnerHtml>{t.mdTestimonial}</DangerouslySetInnerHtml>
          </PControl>
          <P>-{t.author}</P>
        </Testimonial>
      ))}
    </Wrapper>
  </Layout>
);

export default TestimonialPage;

interface ITestimonialPageQuery {
  content: {
    frontmatter: {
      title: string;
      heroImg: IChildImageSharpFluid;
      testimonials: {
        mdTestimonial: string;
        author: string;
      }[];
    };
  };
}

export const query = graphql`
  query TestimonialsPageQuery {
    content: markdownRemark(
      frontmatter: { templateKey: { eq: "testimonials-page" } }
    ) {
      frontmatter {
        title
        heroImg {
          childImageSharp {
            fluid(maxWidth: 2000, quality: 80) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        testimonials {
          mdTestimonial
          author
        }
      }
    }
  }
`;
