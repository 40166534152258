import React from "react";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { StaticQuery, graphql } from "gatsby";
import { colors, mediaQuery } from "../../utilities";
import { ButtonWrapper } from "../button";
import { Link } from "../link";
import { Wrapper } from "../wrapper";

const BannerWrapper = styled.div({
  backgroundColor: colors.blue.base,
  color: colors.white,
});

const FlexWrapper = styled.div({
  display: "block",
  justifyContent: "space-between",
  paddingTop: 16,
  paddingBottom: 16,
  alignItems: "center",
  [mediaQuery[0]]: {
    display: "flex",
  },
});

const Message = styled.h3({
  margin: 0,
  color: colors.white,
});

interface IBannerProps {}

interface IBannerQueryData {
  header: {
    frontmatter: {
      bannerContent: string;
      bannerBtn: string;
    };
  };
}

export const Banner: React.SFC<IBannerProps> = () => (
  <BannerWrapper>
    <Wrapper>
      <FlexWrapper>
        <StaticQuery
          query={graphql`
            query BannerQuery {
              header: markdownRemark(
                frontmatter: { templateKey: { eq: "header" } }
              ) {
                frontmatter {
                  bannerContent
                  bannerBtn
                }
              }
            }
          `}
          render={(data: IBannerQueryData) => (
            <>
              <Message>{data.header.frontmatter.bannerContent}</Message>
              <ButtonWrapper.Outline>
                <Link to="#contact">{data.header.frontmatter.bannerBtn}</Link>
              </ButtonWrapper.Outline>
            </>
          )}
        />
      </FlexWrapper>
    </Wrapper>
  </BannerWrapper>
);

Banner.displayName = "Banner";
